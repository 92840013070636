import React from "react";
import { graphql } from 'gatsby';
import Modelling from "../../templates/ModellingTemplate";

export default function HistoryPage({ data: { page } }) {
    
	return <Modelling page={page} locale="sv" />
}

export const query = graphql`
fragment MuovailuImage on File {
    childImageSharp {
        fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
        }
    }
}
query {
    page: wpPage(slug: { eq: "modelleraprofetia" }) {
        content
        title
        frontpageLinkTitle {
            styledTitle
        }
        locale {
            locale
        }
        profetiaData {
            profetiaRepeater {
                profetiaHeading
                profetiaText
                profetiaImage {
                    localFile {
                        childImageSharp { 
                            gatsbyImageData(width: 220)
                        }
                    }
                }
            }
        }
        seo {
            metaDesc
            title
        }
    }
}
`;
